import React from "react";
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const styles = {
    resourceTitle: {
        'textAlign': 'left',
        'fontWeight': 'bold'
    },
    notThere: {
        'textAlign': 'left',
        'textDecoration': 'line-through',
        'fontStyle': 'italic',
        'color': '#aaa'
    }
}
let testParams = [];
const TestRows = (searchParams) =>
{
    if(!searchParams.data) {
        return (
            <tbody>
                <tr>
                    <td>There are no search params for this element</td>
                </tr>
            </tbody>
        )
    }
    return (
        <tbody>
        {searchParams.data.map( (param, index) => (
            <tr key={index}>
                <td>{param.name}</td>
                <td>{param.type}</td>
                <td>{param.documentation}</td>
            </tr>
            ))}
        </tbody>
    )
}
function testPopover(props) {
    let rows = '';
    if(props.popper.state) {
        testParams = props.popper.state.options.testObj.searchParam;
    }

    return (
        <Popover className="thisPopover" id="popover-basic" {...props}>
            <Popover.Header as="h3">Search Parameters</Popover.Header>
            <Popover.Body>
                <table className="searchParamTable">
                    <thead>
                    <tr>
                    <th>Parameter</th>
                    <th>Type</th>
                    <th>Description</th>
                    </tr>
                    </thead>
                    <TestRows data={testParams}></TestRows>
                </table>
            </Popover.Body>
        </Popover>
    )
}

export default function Resource(props) {
    return (
        <tr className={!props.resource.inMetadata ? 'notThere' : props.resource.isEqual ? 'equal' : 'notEqual'}>
            <td style={props.resource.inMetadata ? styles.resourceTitle : styles.notThere}>
                <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={testPopover} popperConfig={{testObj:props.resource}}>
                    <span className="resourceText">{props.resource.type}</span>
                </OverlayTrigger>
            </td>
            <td>
                <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canRead ? faCircleCheck: faTimes : faMinus} />
            </td>
            <td>
                <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canSearchType ? faCircleCheck: faTimes : faMinus} />
            </td>
            <td>
                <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canCreate ? faCircleCheck: faTimes : faMinus} />
            </td>
            <td>
                <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canUpdate ? faCircleCheck: faTimes : faMinus} />
            </td>
            <td>
                <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canDelete ? faCircleCheck: faTimes : faMinus} />
            </td>
            {/*<td>*/}
            {/*    <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canPatch ? faCircleCheck: faTimes : faMinus} />*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canHistoryInstance ? faCircleCheck: faTimes : faMinus} />*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    <FontAwesomeIcon icon={props.resource.inMetadata ? props.resource.canHistoryType ? faCircleCheck: faTimes : faMinus} />*/}
            {/*</td>*/}
        </tr>
    );
}

import './App.css';
import { CSVLink, CSVDownload } from "react-csv";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FhirMetadata from './components/fhirMetadata'
import FhirResources from "./components/fhirResources";
import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';

function App() {

    const [url, setUrl] = useState("");
    const [metadataText, setMetadataText] = useState("");
    const [csvData, setCsvData] = useState([]);

    const [fhirServers, setFhirServers] = useState([]);
    const [allResources, setAllResources] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const errors = formErrors.map( (error, index) => {
        return (
            <span key={index}>{error}</span>
        );
    });

    function handleChange(event) {
        setUrl(event.target.value);
    }

    function increaseTextareaSize(event, focus) {
        event.target.rows = 20;
    }

    function reduceTextareaSize(event, focus) {
        event.target.rows = 1;
    }

    function handlePasteMetadataChange(event) {
        setMetadataText(event.target.value);
    }
    function handlePasteMetadata() {
        addFhirServer(JSON.parse(metadataText));
        setMetadataText("");
    }

    function handleAddCedars(event) {
        setUrl( "https://cslinkmobile.csmc.edu/fhirproxy/api/FHIR/R4/metadata");
    }

    function handleAddHapi(event) {
        setUrl( "http://hapi.fhir.org/baseR4/metadata");
    }

    function handleAddEpicR3(event) {
        setUrl( "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/STU3/metadata");
    }

    function handleAddEpicR4(event) {
        setUrl( "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/metadata");
    }

    function massageData(servers) {
        let theseResources = [];
        // remove all inMetadata false resoures for reset
        console.log(servers);
        servers.forEach( server => {
            server.resources = server.resources.filter( resource =>
                resource.inMetadata !== false
            )
        });
        servers.forEach( server => {
            server.resources.forEach( resource => {
                // populate theseResources with all available mentioned
                if(theseResources.indexOf(resource.type) == -1) {
                    theseResources.unshift(resource.type);
                }
                resource.inMetadata = true;
            })
        });
        servers.forEach( server => {
            theseResources.forEach( resource => {
                if(!server.resources.find(o => o.type === resource)) {
                    server.resources.unshift({
                        type: resource,
                        inMetadata: false
                    });
                }
            })
            server.resources.sort((a, b) =>
                a.type < b.type ? -1 : a.type > b.type ? 1 : 0
            );
        })
        // find unequal resources
        const allEqual = arr => arr.every(val => val === arr[0]);
        let tempCreateArray = [];
        let tempReadArray = [];
        let tempSearchTypeArray = [];
        let tempUpdateArray = [];
        let tempDeleteArray = [];
        for(let i = 0; i < theseResources.length; i++) {
            servers.forEach( server => {
                if(server.resources[i].inMetadata) {
                    tempCreateArray.push(server.resources[i].canCreate)
                    tempReadArray.push(server.resources[i].canRead)
                    tempSearchTypeArray.push(server.resources[i].canSearchType)
                    tempUpdateArray.push(server.resources[i].canUpdate)
                    tempDeleteArray.push(server.resources[i].canDelete)
                }
            })
            let isEqual = allEqual(tempCreateArray) && allEqual(tempReadArray) && allEqual(tempSearchTypeArray) && allEqual(tempUpdateArray) && allEqual(tempDeleteArray);
            servers.forEach( server => {
                server.resources[i].isEqual = isEqual;
            });
            tempCreateArray = [];
            tempReadArray = [];
            tempSearchTypeArray = [];
            tempUpdateArray = [];
            tempDeleteArray = [];
        }
        prepareCsv(servers);
        return servers;
    }

    function prepareCsv(servers) {
        let csv = [];
        let tempArray = [
            ['URL'],
            ['fhirVersion'],
            ['name'],
            ['status'],
            ['date'],
            ['publisher'],
            ['kind'],
            ['software'],
            ['implementation'],
            ['Copyright']
        ];

        servers.forEach( (server, index) => {
            tempArray[0].push(server.conformanceUrl);
            tempArray[1].push(server.metadata.fhirVersion);
            tempArray[2].push(server.metadata.name);
            tempArray[3].push(server.metadata.status);
            tempArray[4].push(server.metadata.date);
            tempArray[5].push(server.metadata.publisher);
            tempArray[6].push(server.metadata.kind);
            tempArray[7].push(server.metadata.software ? `name: ${server.metadata.software.name} 
version: ${server.metadata.software.version}` : '');
            tempArray[8].push(server.metadata.implementation ? `description: ${server.metadata.implementation.description} 
url: ${server.metadata.implementation.url}` : '');
            tempArray[9].push(server.metadata.copyright);
            server.resources.forEach( (resource, resourceIndex) => {
                if(index === 0) {
                    tempArray.push([resource.type]);
                }
                if(!resource.inMetadata) {
                    tempArray[resourceIndex + 10].push('')
                }
                else {
                    // tempArray[resourceIndex + 10].push(
                    //     `${JSON.stringify(resource).replace(/"/g, "'")} `
                    // )
                    tempArray[resourceIndex + 10].push(
                        `read: ${resource.canRead ? "True" : "False"}
create: ${resource.canCreate ? "True" : "False"}
update: ${resource.canUpdate ? "True" : "False"}
delete: ${resource.canDelete ? "True" : "False"}
vread: ${resource.canVRead ? "True" : "False"}
search-type: ${resource.canSearchType ? "True" : "False"}
patch: ${resource.canPatch ? "True" : "False"}
history-instance: ${resource.canHistoryInstance ? "True" : "False"}
history-type: ${resource.canHistoryType ? "True" : "False"}`
                    )
                }
            })
        });
        setCsvData(tempArray);
    }

    useEffect(() => {
    }, [])

    function handleSubmit() {
        console.log(formErrors)
        if(url == "") {
            setFormErrors(['URL cannot be empty']);
            return;
        }
        if(url.indexOf('metadata') < 0) {
            setFormErrors(['URL must end with "/metadata". Example: https://cslinkmobile.csmc.edu/fhirproxy/api/FHIR/R4/metadata']);
            return;
        }
        getMetadata()
            .then( server => {
                console.log(server);
                addFhirServer(server);
                // let servers = fhirServers.map((x) => x);
                // servers.push(server);
                // setFhirServers(fhirServers =>  massageData(servers))
            })
            .catch( error => {
                setFormErrors([`There was an error fetching from URL '${url}'. Please try again. Example: https://cslinkmobile.csmc.edu/fhirproxy/api/FHIR/R4/metadata`]);
            })
        setFormErrors(['']);
        setUrl("");
    }
    function addFhirServer(server) {
        console.log(server);
        let r = server.rest[0].resource;
        r.forEach(resource => {
            resource.inMetadata = true;
            if(resource.interaction && resource.interaction.find(o => o.code === 'create')) {
                resource.canCreate = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'read')) {
                resource.canRead = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'vread')) {
                resource.canVRead = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'search-type')) {
                resource.canSearchType = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'update')) {
                resource.canUpdate = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'delete')) {
                resource.canDelete = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'patch')) {
                resource.canPatch = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'history-instance')) {
                resource.canHistoryInstance = true;
            }
            if(resource.interaction && resource.interaction.find(o => o.code === 'history-type')) {
                resource.canHistoryType = true;
            }
        });

        let servers = fhirServers.map((x) => x);
        servers.push({
            conformanceUrl: url,
            resources: r,
            metadata: server
        });
        setFhirServers(fhirServers =>  massageData(servers))
    }
    async function getMetadata() {
        return new Promise ( (resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    "accept": "application/json"
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve(result);

                    }
                )
                .catch( error => {
                    console.log(error);
                    reject();
                })
        })

    }
    function removeServer(url) {
        let servers = fhirServers.map((x) => x);
        servers = servers.filter( server =>
            server.conformanceUrl != url
        )
        setFhirServers(fhirServers =>  massageData(servers));
    }

    const fhirServersMetadata = fhirServers.map( (next, index) => {
        return  (
            <Col key={index}>
                <FhirMetadata conformanceUrl={next.conformanceUrl} metadata={next.metadata} handleRemove={removeServer} ></FhirMetadata>
            </Col>
        )
    });

    const fhirResourcesDisplay = fhirServers.map((next, index) => {
        return  (
            <Col key={index}>
                <FhirResources resources={next.resources}></FhirResources>
            </Col>
        )
    });

    return (
        <Container className="site">
            <Row>
                <Col>
                    <Card className='urlForm'>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col xs={5}>
                                        <Form.Control type="text" value={url} onChange={handleChange} placeholder="Enter Metadata URL of FHIR Server" />
                                        {errors}
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Control rows="1" className="metadataTextarea" as="textarea" onFocus={increaseTextareaSize} onBlur={reduceTextareaSize} value={metadataText} onChange={handlePasteMetadataChange} placeholder="Paste metadata text" />
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={handlePasteMetadata}>Submit</Button>
                                    </Col>
                                </Row>
                                {/*<Row className="p-3">*/}
                                {/*    <Col>*/}
                                {/*        <Button variant="primary" onClick={handleAddCedars}>Add Cedars</Button>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <Button variant="primary" onClick={handleAddEpicR3}>Add Epic R3</Button>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <Button variant="primary" onClick={handleAddEpicR4}>Add Epic R4</Button>*/}
                                {/*    </Col>*/}
                                {/*    <Col xs={9}>*/}

                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {fhirServers.length > 0 ?
                <Row>
                    <Col>
                        <CSVLink data={csvData}>Download CSV</CSVLink>
                    </Col>
                </Row>
                : <Row></Row>
            }
            <Row>
                {fhirServersMetadata}
            </Row>
            <Row>
                {fhirResourcesDisplay}
            </Row>
        </Container>
    );
}
export default App;

import React, {useEffect} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Resource from "./resource";
import Card from 'react-bootstrap/Card';

const styles = {
    resourceTitle: {
        'textAlign': 'left'
    }
}

export default function FhirResources(props) {
    const resourceElements = props.resources.map( (next, index) => {
        return <Resource key={next.type} resource={next}></Resource>
    });

    return (
            <Col className="col-12">
                <Card className='resources'>
                    <Card.Body>
                        <table className='resourceTable'>
                            <thead>
                                <tr>
                                    <th style={styles.resourceTitle}>Resource</th>
                                    <th>Read</th>
                                    {/*<th>VRead</th>*/}
                                    <th>Search-Type</th>
                                    <th>Create</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                    {/*<th>Patch</th>*/}
                                    {/*<th>History</th>*/}
                                    {/*<th>History-Type</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {resourceElements}
                            </tbody>

                        </table>
                    </Card.Body>
                </Card>
            </Col>
    );
}

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';

export default function FhirMetadata(props) {

        return (
            <Col className="col-12">
                <Card className='metadata'>
                    <Card.Header>
                        {/*{props.conformanceUrl}*/}

                            <div>
                                <Row>
                                    {props.metadata.implementation ?
                                    <Col xs={2} className="text-nowrap">
                                        <table>
                                            <tr>
                                                <td>Description:</td>
                                                <td>&nbsp;{props.metadata.implementation.description}</td>
                                            </tr>
                                            <tr>
                                                <td>URL:</td>
                                                <td>&nbsp;{props.metadata.implementation.url}</td>
                                            </tr>
                                        </table>
                                    </Col>
                                        : <Col></Col>
                                    }
                                    <Col ><Button className='removeButton' onClick={() => props.handleRemove(props.conformanceUrl)}>Remove</Button></Col>
                                </Row>
                            </div>

                    </Card.Header>
                    <Card.Body>
                        {/*<Row>*/}
                        {/*    <Col xs={3}>Implementation:</Col>*/}
                        {/*    {props.metadata.implementation ?*/}
                        {/*        <Col>*/}
                        {/*            Description: {props.metadata.implementation.description}<br/>*/}
                        {/*            URL: {props.metadata.implementation.url}<br/>*/}
                        {/*        </Col>*/}
                        {/*        : <Col></Col>*/}
                        {/*    }*/}
                        {/*</Row>*/}
                        <Row>
                            <Col xs={3}>FHIR Version:</Col>
                            <Col>{props.metadata.fhirVersion}</Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Status:</Col>
                            <Col>{props.metadata.status}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Experimental:</Col>
                            <Col>{props.metadata.experimental ? props.metadata.experimental.toString() : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>kind:</Col>
                            <Col>{props.metadata.kind}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Copyright:</Col>
                            <Col>{props.metadata.copyright}</Col>
                        </Row>
                        <Row>
                            <Col xs={3}>Software:</Col>
                            {props.metadata.software ?
                                <Col>
                                    Name: {props.metadata.software.name}<br/>
                                    Version: {props.metadata.software.version}<br/>
                                    Release Date: {props.metadata.software.releaseDate}
                                </Col>
                                : <Col></Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        )
}
